.footer-container {
  background-color: #151515;
  height: 160px;
  padding: 1rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-container > small{
  color: #333333;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  justify-content: center;
  width: 80%;
  white-space: pre-line;
}


