/* webpackIgnore: true */
.who-container{
    height: 500px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-top: 20px; */
    padding-left: 200px;
    padding-right: 200px;
    background-color: black;
    font-family: 'Lato', sans-serif;
}
.after-who{
    height: 513px;
    width: 100%;
    background-color: #000;
    background-image: url('./images/afterWho.webp');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    
}


.who-header{
    color: rgb(255, 255, 255);
    font-size: 30px;
    
    margin: 20px;
    justify-items: center;
    text-align: center;
    /* text-shadow: 2px 2px 4px #8c8c8c; */

}

.description{
    
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: left;
    margin:20px;
    width: 80%;
    white-space: pre-line;
}
.description:nth-child(1){
    margin-top: 60px;
}

.line{
    background-color: #00f0ff;
    width: 80px;
    height: 3px;
}


@media screen and (max-width: 768px ) {
    .who-container{
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 30px;
    }
    .who-header{
        color: rgb(255, 255, 255);
        font-size: 20px;
        margin: 20px;
        justify-items: center;
        text-align: center;
        /* text-shadow: 2px 2px 4px #8c8c8c; */
    
    }
    .after-who{
        height: 300px;
    }
    .description{
    
        color: rgb(255, 255, 255);
        font-size: 14px;
        text-align: left;
        margin:10px;
        width: 80%;
        white-space: pre-line;
        
    }
    .description:nth-child(1){
        margin-top: 0px;
    }


}

