video {
  object-fit: cover;
  width: 100%;
  height: auto;
  position: fixed;
  z-index: -1;
  opacity: 1.0;
}

.hero{
  /* background: url('./images/1.jpg') center center/cover no-repeat; */
  height: calc(100vh - 80px) !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero > p {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
}
.scroll-icon{
  background-image: url('./images/scroll.png');
  
  width:43px;
  height: 67px;
}



@media screen and (max-width: 960px) {
  .hero > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 960px) {


  .hero > p {
    font-size: 15px;
    font-weight: 700;
    font-family: 'Lato', sans-serif;
    display: none;
  }
  video {
    display: none;
  }
  .hero{
    background: url('./images/LANDING-PAGE.webp') center center/cover no-repeat;
  }
  .scroll-icon{
    display: none;
  }


}
