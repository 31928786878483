.chip-container{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* padding-top: 20px; */
    padding-left: 200px;
    padding-right: 200px;
    background-color: black;
    font-family: 'Lato', sans-serif;
}

.chip-header{
    color: rgb(255, 255, 255);
    font-size: 30px;
    
    margin-top: 100px;
    margin-bottom: 20px;
    justify-items: center;
    text-align: center;
    /* text-shadow: 2px 2px 4px #8c8c8c; */

}

.line{
    background-color: #00f0ff;
    width: 80px;
    height: 3px;
}

.chip-img-top{
    height: 310px;
    width: 100%;
    background-image: url('./images/BANNER-IMAGE.webp');
    background-position: center;
    background-repeat: no-repeat;
}
.center{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 80px;
    margin-bottom: 80px;
}
.img{
    
    background-position: center;
    background-repeat: no-repeat;
}
.one{
    height: 293px;
    width: 269px;
    background-image: url('./images/chip_1.jpg');
}
.two{
    height: 293px;
    width: 269px;
    background-image: url('./images/chip_2.jpg');
}
.three{
    height: 293px;
    width: 269px;
    background-image: url('./images/chip_3.jpg');
}

.chip-img-bottom{
    height: 310px;
    width: 100%;
    background-image: url('./images/elements-row.png');
    background-position: center;
    background-repeat: no-repeat;
}

.os{
    justify-self: center;
    align-self: center;
    color:#00f0ff;
}
.link{
    color:#00f0ff;
}


@media screen and (max-width: 768px) {
    .chip-container{
        padding-left: 0px;
        padding-right: 0px;
    
    }
    .chip-header{
        font-size: 24px;
        margin-top: 100px;
        margin-bottom: 20px;
    }
    .center{
        align-items: center;
        justify-content: center;
    }


}