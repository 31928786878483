:root {
  --primary: #00f0ff;
  --border: #0066a1;
  --font: #0066a1;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 3px solid var(--border);
  border-radius: 7px;
  transition: all 0.3s ease-out;
}

.mint{
  background-color: #000;
  color: #fff;
  padding: 8px 20px;
  border: 3px solid var(--border);
  border-radius: 7px;
  transition: all 0.3s ease-out;
}
.btn--mobile {
  width:200px;
  padding: 8px 20px;
  font-size: 16px;
}
.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: var(--primary);
  color: var(--font);
  transition: 250ms;
}

.hidden{
  display: none;
}
