video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  height: calc(100vh - 80px) !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4); */
  object-fit: contain;
  font-family: 'Lato', sans-serif;
  
}

.chip{
  background-image: url('./images/e1.webp');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.minted{
  background-image: url('./images/e3.webp');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color:#fff;
  content: "";
}
.inprogress{

  background-image: url('./images/e2.webp');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
  text-shadow: 2px 2px 4px #1d1c1b;
  filter: blur(0.007em);
  animation: shake 2.5s linear forwards infinite;
}
.hero-container > h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  margin-top: -100px;
  text-shadow: 2px 2px 4px #1d1c1b;
}
.paragraph{
  margin-top: 8px;
  color: #fff;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
}
.link{
  cursor: pointer;
  color:#00f0ff;
  text-decoration: none; 
}
.link:hover{
  color:#00f0ff;
}
.small{

  font-size: 18px;
  font-weight:200;
}
.large{
  margin-top: -15px;
  font-size: 72px;
  font-weight:900;
}
.large-minted{
  display: none;
}
.top{
  margin-top:-110px;
  color:#747474;
  animation: shake 2.5s linear forwards infinite;
}
.bottom{
  bottom:130px;
  position:absolute;
  color:#747474;
  text-align: center;
}

@keyframes shake {
  5%, 15%, 25%, 35%, 55%, 65%, 75%, 95% {
      filter: blur(0.003em);
      transform: translateY(0.003em) rotate(0deg);
  }

  10%, 30%, 40%, 50%, 70%, 80%, 90% {
      filter: blur(0.001em);
      transform: translateY(-0.003em) rotate(0deg);
  }

  20%, 60% {
      filter: blur(0.003em);
      transform: translate(-0.003em, 0.003em) rotate(0deg);
  }

  45%, 85% {
      filter: blur(0.003em);
      transform: translate(0.003em, -0.003em) rotate(0deg);
  }

  100% {
      filter: blur(0.003em);
      transform: translate(0) rotate(-0.5deg);
  }
}



.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.spacer{
  margin-top: 32px;
}
.hero-btns {
  display: flex;
  flex-direction: row;
  margin-top: -6px;
}
.btn-mobile{
  align-self: center;
}

.hero-btns .btn {
  margin: 2px;
  
  
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
    text-align: center;
  }
  .hero-container > h2 {
    font-size: 20px;
    margin-top: -100px;
    text-align: center;
    margin-bottom:10px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
  .small{
    margin-bottom: 20px;
    font-size: 18px;
    font-weight:200;
    padding-left:10px;
    padding-right:10px;
    text-align: center;
  }
  .large{
    margin-top: -15px;
    font-size: 52px;
    font-weight:900;
    text-align: center;
  }
  .large-minted{
    display: inline;
    margin-top: -15px;
    font-size: 52px;
    font-weight:900;
    text-align: center;
  }
  .top{
    margin-top:-110px;
    color:#747474;
    animation: shake 2.5s linear forwards infinite;
  }
  .bottom{
    bottom:130px;
    position:absolute;
    color:#747474;
    text-align: center;
    font-size: 13px;
  }
  .minted{
    background-image: none;
    background-color: black;
  }
  .link{
    cursor: pointer;
    color:#0066a1;
    text-decoration: none;

    text-align: center;
    word-break: break-all;
  }
}
