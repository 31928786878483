
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

.landing{
    position:relative;
    width:100%;
    height:100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("./images/LANDING-PAGE.webp");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.social-container {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position:absolute;
    top:1vh;
    right:60px;
}
.sound-container{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position:absolute;
    bottom:3%;
    right:3%;
}
.sound{
    color:#fff;
    font-size:34px;
    width:24px;
}
.sound:hover{
    cursor:pointer;
}
.social{
    margin-right: 20px;
}
.landing-video{
    object-fit:fill;
    min-width: 100%; 
    min-height: 100%; 
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.trailer-btn{
    margin-top:240px;
    color:#fff;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 900;
    padding:20px 20px;
    border: 1px solid;
    border-radius: 6px;
    border-color: #00f0ff;
    text-decoration: none;
    cursor:pointer;
    background-color: rgb(54, 54, 54);
}

.trailer-btn:hover{
    background-color: #00f0ff;
    transform: scale(1.05);
    
}
@media screen and (max-width: 960px) {
    .trailer-btn{
        margin-top:200px;
        color:#fff;
        font-family: 'Lato', sans-serif;
        font-size: 12px;
        font-weight: 900;
        line-height: 10px;
        padding:15px 10px;
        border: 1px solid;
        border-radius: 6px;
        border-color: #00f0ff;
        text-decoration: none;
        cursor:pointer;
        background-color: rgb(54, 54, 54);
    }

}