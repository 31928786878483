.navbar {
  background: #000;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-items: center;
  margin-left: -25px;
}
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  /* max-width: 1500px; */
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  width:180px;
  height: 80px;
  margin-top:-6px;
  background-image: url("./images/LOGO.webp");
}

.navbar-logo:hover{
    color:white;
}


.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.address{
    color: #fff;
    margin-top:20px;
    font-family: 'Lato', sans-serif;
}


.social-container-nav {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
}

.discord{
  width:36px;
  height: 36px;
  background-image: url("./images/discord.webp");
  background-position: center;
  background-attachment:initial;
  background-repeat: no-repeat;
  margin-top: 30px;
  margin-right: 20px;
}
.twitter{
  width:50px;
  height: 50px;
  background-image: url("./images/twitter.webp");
  background-position: center;
  background-attachment:initial;
  background-repeat: no-repeat;
  margin-top: 30px;
}

.close{
  width:30px;
  height: 30px;
  background-image: url(./images/times-solid.svg);
  filter: invert(19%) sepia(73%) saturate(3376%) hue-rotate(186deg) brightness(97%) contrast(101%);
  background-position: center;
  background-attachment:initial;
  background-repeat: no-repeat;
  margin-top: 5px;
}
.open{
  width:30px;
  height: 30px;
  background-image: url(./images/bars-solid.svg);
  filter: invert(19%) sepia(73%) saturate(3376%) hue-rotate(186deg) brightness(97%) contrast(101%);
  background-position: center;
  background-attachment:initial;
  background-repeat: no-repeat;
  margin-top: 5px;
}
.mobile{
  display: none;
}
.mobile-btn{
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }
  .right{
    display: none;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    margin-left:0px;
  }
  .navbar-logo:hover{
    color:white;
}

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
  .social-container-nav {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 0px;

  }
  .address-mobile{
    color: #fff;
    margin-top:20px;
    font-family: 'Lato', sans-serif;
    font-size: 13px;
  }
.mobile-btn{
  display: flex;
  justify-content: center;
}
}
