@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,500;1,700&display=swap');
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

.roadmap-header{
    /* transform: translate(-800px, 0px); */
    /* opacity:0%; */
    text-align: center;
    color: #fff;
    mix-blend-mode: screen;
    margin-top:-50px;
    margin-bottom: 40px;
    
}
.cntr{
    margin-left:48%;
    margin-bottom:40px;
    margin-top:-20px;
}
.space{
    height: 213px;
    width: 100%;
    background-color: #00f0ff;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.space > p{
    color: rgb(255, 255, 255);
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    width: 80%;
    white-space: pre-line;
}
p > a{
    text-decoration: none;
    color:#000;

}
.timeline-section{
    
    background-color: #000;
    min-height: 100vh;
    padding: 100px 15px;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0px 11px 20px -8px rgba(12, 12, 12, 1);
}

.timeline-items{
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    
}
.timeline-items::before{
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: #00f0ff;
    left: calc(50% - 1px);

}
.timeline-item{
    margin-bottom: 40px;
    width: 100%;
    position: relative;
    
}

.timeline-item:last-child{
    margin-bottom:0;

}
.timeline-item:nth-child(odd){
    padding-right: calc(50% + 30px);
    text-align: right;
}

.timeline-item:nth-child(even){
    padding-left: calc(50% + 30px);
    text-align: left;
}
.timeline-dot{
    height: 16px;
    width: 16px;
    background-color: #00f0ff;
    position: absolute;
    left:calc(50% - 8px);
    border-radius: 50%;
    top:10px;
    border: 0px solid snow;
    /* box-shadow: 3px 3px 4px -3px rgba(254, 205, 39, 1); */
}

.timeline-date{
    font-size: 16px;
    color:#00f0ff;
    margin: 6px 0 15px;
}

.timeline-content{
    background-color: #2f363e;
    padding: 30px;
    border-radius: 8px;

}

.timeline-content h2{
    font-size: 20px;
    color: #fff;
    margin: 0 0 15px;
    font-weight: 500;
}
.timeline-content p{
    font-size: 16px;
    color: #c8c8c8;
    font-weight: 300;
    line-height: 22px;
}

/* responsive */

@media(max-width: 767px){
    .timeline-items::before{
        left:7px;
    }
    .timeline-dot{
        left: 0;
    }
    .timeline-item:nth-child(odd){
        padding-right: 0;
        text-align: left;
    }
    .timeline-item:nth-child(odd),
    .timeline-item:nth-child(even){
        padding-left: 37px;
    }
    .cntr{
        margin-left:39%;
        margin-bottom:40px;
        margin-top:-20px;
    }
}