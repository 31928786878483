.trailer-container{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* padding-top: 20px; */
    padding-left: 200px;
    padding-right: 200px;
    background-color: black;
    font-family: 'Lato', sans-serif;
}
.below{
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin:10px;
    margin-left:50px;
    width: 70%;
    white-space: pre-line;

}
.trailer-header{
    color: rgb(255, 255, 255);
    font-size: 30px;
    margin: 20px;
    justify-items: center;
    text-align: center;
    
}
iframe {
    border-width: 0px;
    border-style: inset;
    border-color: initial;
    border-image: initial;
    width:1020px;
    height:550px;
}

@media screen and (max-width: 968px) {
    iframe {
        width:360px;
        height:300px;
    }
}
@media screen and (max-width: 768px) {
    .trailer-container{
        height: 100vh;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 30px;
    }

    .trailer-header{
        font-size: 24px;
    }
    iframe {
        width:360px;
        height:300px;
    }
    .below{
        color: rgb(255, 255, 255);
        font-size: 13px;
        font-weight: 400;
        text-align: left;
        margin:5px;
        margin-left:0px;
        width: 80%;
        white-space: pre-line;
    
    }

}